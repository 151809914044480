import React, { useCallback, useContext, useState, useEffect } from "react";
import Valuation from "./Valuation";
import ModelOverview from "./ModelOverview";
import RelatedVehicles from "./RelatedVehicles";
import PriceChart from "./PriceChart";
import VehicleComps from "./VehicleComps"; 
import { ResultsWrapper } from "./results.styled";
import { navigate } from "gatsby";
import Link from "../../utils/link";
import { Link as ScrollLink } from "react-scroll";
import { ACIContext } from "../../contexts/ACIContext";
import { BrandContext } from "../BrandContext/brandContext";
import Features from "./Features";
import SalesHistoryCharts from "./SalesHistoryCharts";
import GenerationButtons from "./GenerationButtons/GenerationButtons";
import VariantButtons from "./VariantButtons/VariantButtons"; // Corrected import path
import SalesHistory from "./SalesHistory/index";

const ResultsMain = () => {
  const {
    makeLabel,
    model,
    year,
    trim,
    description: initialDescription,
    chartUrl: initialChartUrl,
    noChart,
    relatedVehicles,
    resetForm,
    optionsList,
    comps, // Access the comps from context
  } = useContext(ACIContext);

  const brandContext = useContext(BrandContext) || {};
  const { urlBrand = "" } = brandContext;

  const [activeChart, setActiveChart] = useState("Average Value");
  const [description, setDescription] = useState(initialDescription); // Default to initial description
  const [chartUrl, setChartUrl] = useState(initialChartUrl); // Default to initial chart URL
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedGeneration, setSelectedGeneration] = useState(null);
  const [filteredComps, setFilteredComps] = useState(comps);
  const handleReset = useCallback(() => resetForm(), [resetForm]);

  const handleActiveChart = useCallback((chart) => setActiveChart(chart), []);

  // Function to update chartUrl and description
  const handleChartAndDescriptionUpdate = useCallback(
    (newChartUrl, newDescription) => {

      setChartUrl(newChartUrl);
      setDescription(newDescription);
    },
    []
  );

  const restartLink = urlBrand ? `/?brand=${urlBrand}` : "/";

  const handleRestartClick = (event) => {
    event.preventDefault();
    handleReset();
    navigate(restartLink);
  };

  return (
    <ResultsWrapper>
      <div className="container results-container">
        <div className="sidebar-items">
        <ScrollLink
          spy
          smooth
          duration={250}
          offset={-200}
          activeClass="active"
          to="valuation"
          className="side-item"
        >
          Valuation
        </ScrollLink>
        {activeChart === "Sales History" && comps?.length > 0 && (
          <ScrollLink
            spy
            smooth
            duration={250}
            offset={-150}
            activeClass="active"
            to="buttonWidgetContainer" 
            className="side-item"
          >
            Sales History
          </ScrollLink>
        )}
        {activeChart === "Average Value" && chartUrl && (
          <ScrollLink
            spy
            smooth
            duration={250}
            offset={-125}
            activeClass="active"
            to="salesHistory"
            className="side-item"
          >
            Sales History
          </ScrollLink>
        )}


        {activeChart === "Sales History" && comps?.length > 0 && (
          <ScrollLink
            spy
            smooth
            duration={250}
            offset={-150}
            activeClass="active"
            to="vehicleComps"
            className="side-item"
          >
            Vehicle Comps
          </ScrollLink>
        )}

        {description && (
          <ScrollLink
            spy
            smooth
            duration={250}
            offset={-150}
            activeClass="active"
            to="modelOverview"
            className="side-item"
          >
            Model Overview
          </ScrollLink>
        )}
        {year && makeLabel && model && (
          <ScrollLink
            spy
            smooth
            duration={250}
            offset={-150}
            activeClass="active"
            to="forSale"
            className="side-item"
          >
            Available for Sale
          </ScrollLink>
        )}
        {relatedVehicles?.length > 0 && (
          <ScrollLink
            spy
            smooth
            duration={250}
            offset={-150}
            activeClass="active"
            to="relatedVehicles"
            className="side-item"
          >
            Related Vehicles
          </ScrollLink>
        )}
      </div>
        <div className="content-wrapper">
          <Valuation
            carName={`${year} ${makeLabel} ${model}`}
            trim={`${trim}`}
          />

          <SalesHistoryCharts
            key={chartUrl}
            noChart={!chartUrl}
            chartUrl={chartUrl}
            activeChart={activeChart}
            onChangeActiveChart={handleActiveChart}
          >
          </SalesHistoryCharts>

          {/* Sales History Tab: Generation and Variant Buttons */}
          {activeChart === "Sales History" && (
            <>
              <div id="buttonWidgetContainer" className="button-widget-container">
                <h5 className="sales-title">Sales history</h5>
                <p className="button-instructions">Select vehicle generation and options</p>
                {/* Generation Buttons Section */}
                <div className="generation-buttons-section">
                  {comps?.length > 0 ? (
                    <GenerationButtons
                      uniqueGeneration={[...new Set(comps.map((comp) => comp.gen))]}
                      selectedGeneration={selectedGeneration}
                      setSelectedGeneration={setSelectedGeneration}
                      comps={comps}
                      setFilteredComps={setFilteredComps}
                      updateChartAndDescription={handleChartAndDescriptionUpdate}
                    />
                  ) : (
                    <p style={{ textAlign: "center", color: "#475467" }}>
                      No data available
                    </p>
                  )}
                </div>

                {/* Variant Buttons */}
                {selectedGeneration && (
                  <div id="variantButtons" className="variant-buttons-section">
                    <VariantButtons
                      uniqueVariants={[...new Set(comps.map((comp) => comp.variant))]}
                      comps={comps}
                      selectedVariant={selectedVariant}
                      setSelectedVariant={setSelectedVariant}
                      setFilteredComps={setFilteredComps}
                      selectedGeneration={selectedGeneration}
                      updateChartAndDescription={handleChartAndDescriptionUpdate}
                    />
                    
                  </div>
                  
                )}
                
                  {/* Sales History */}
                  <SalesHistory noChart={noChart} chartUrl={chartUrl} />
              </div>             
            </>
          )}

          {/* Vehicle Comps */}
          {activeChart === "Sales History" && comps?.length > 0 && (
            <div className="vehicle-comps">
              <VehicleComps
                comps={filteredComps}
                updateChartAndDescription={handleChartAndDescriptionUpdate}
              />
            </div>
          )}

          {optionsList?.length > 0 && activeChart === "Average Value" && (
            <Features />
          )}
          {description && <ModelOverview key={description} description={description} />}
          {year && makeLabel && model && (
            <PriceChart
              carName={`${year} ${makeLabel} ${model}s`}
              year={year}
              make={makeLabel}
              model={model}
            />
          )}
          {relatedVehicles?.length > 0 && <RelatedVehicles />}
          <Link
            to={restartLink}
            className="restart-calc"
            onClick={handleRestartClick}
          >
            <span>
              Looking to get the valuation of another car? Restart calculation
            </span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 18L15 12L9 6"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
      </div>
    </ResultsWrapper>
  );
};

export default ResultsMain;
