import React from "react";

const ChartContainer = ({ children, activeChart }) => (
  <div
    className={`container bg-white ${activeChart === "Sales History" ? "d-none" : "sales-history"}`}
    id="salesHistory"
  >
    {children}
  </div>
);

export default ChartContainer;
