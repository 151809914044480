import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { VariantButtonsWrapper } from "./VariantButtons.styled";

const VariantButtons = ({
  comps,
  selectedVariant,
  setSelectedVariant,
  updateChartAndDescription,
  setFilteredComps,
  selectedGeneration,
}) => {
  // Filter comps for the selected generation
  const filteredCompsByGeneration = useMemo(() => {
    return comps.filter((comp) => comp.gen === selectedGeneration);
  }, [comps, selectedGeneration]);

  // Get unique variants for the selected generation
  const uniqueVariants = useMemo(
    () => [...new Set(filteredCompsByGeneration.map((comp) => comp.variant))],
    [filteredCompsByGeneration]
  );

  // Handle variant button click
  const handleVariantClick = (variant) => {
    const filtered = filteredCompsByGeneration.filter(
      (comp) => comp.variant === variant
    );
    const selectedComp = filtered[0]; // Select the first comp for the variant

    setSelectedVariant(variant);
    setFilteredComps(filtered); // Update filtered vehicle comps
    if (selectedComp) {
      updateChartAndDescription(selectedComp.widget, selectedComp.desc); // Update widget and description
    }
  };

  // Auto-select the first variant by default when generation changes
  useEffect(() => {
    if (uniqueVariants.length && !selectedVariant) {
      handleVariantClick(uniqueVariants[0]); // Select the first variant
    }
  }, [uniqueVariants, selectedVariant]);

  if (!comps || comps.length === 0) {
    return <div>Loading options...</div>;
  }

  if (filteredCompsByGeneration.length === 0) {
    return <div>No options available for this generation.</div>;
  }

  return (
    <VariantButtonsWrapper>
      {/* Add Heading for Variants */}
      <h3>{`${selectedGeneration} Options`}</h3>

      {/* Variant Buttons Section */}
      <div className="shared-buttons variant-buttons">
        {uniqueVariants.map((variant, index) => (
          <button
            key={index}
            onClick={() => handleVariantClick(variant)}
            className={variant === selectedVariant ? "active" : ""}
          >
            {variant}
          </button>
        ))}
      </div>
    </VariantButtonsWrapper>
  );
};

VariantButtons.propTypes = {
  comps: PropTypes.array.isRequired,
  selectedVariant: PropTypes.string,
  setSelectedVariant: PropTypes.func.isRequired,
  updateChartAndDescription: PropTypes.func.isRequired,
  setFilteredComps: PropTypes.func.isRequired,
  selectedGeneration: PropTypes.string.isRequired, // Generation must be selected
};

export default VariantButtons;
