import styled from "styled-components";

export const GenerationButtonsWrapper = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 0px;


  h5 {
      font-family: "Raleway", sans-serif;
      font-weight: 600;
      font-size: 20px;
      color: #163750;
  }

  /* Generation Buttons Section */
  .generation-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    button {
      padding: 8px 12px; /* Align with VariantButtons padding */
      border: 1px solid #5b89b4; /* Light Blue Border */
      background-color: #fff; /* White Background */
      color: #5b89b4; /* Light Blue Text */
      border-radius: 4px; /* Match VariantButtons border radius */
      font-size: 14px; /* Same font size as VariantButtons */
      cursor: pointer;
      transition: all 0.2s ease; /* Smooth transition for hover and active states */

      font-family: "Raleway", sans-serif;
      font-style: normal;
      font-weight: 500;
      max-height: 36px;

      &:hover {
        background-color: #5b89b4; /* Light Blue Background */
        color: #fff; /* White Text */
      }

      &.active {
        background-color: #236092; /* Dark Blue Background */
        color: #fff; /* White Text */
        border: 1px solid #236092; /* Dark Blue Border */
      }
    }
  }

  @media (max-width: 768px) {
    .generation-buttons {
      flex-direction: column; /* Stack buttons vertically on smaller screens */
      gap: 15px; /* Add more space between buttons */
    }

    button {
      width: 100%; /* Full width for better tap area on smaller screens */
      text-align: center;
    }
  }
`;
