import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GenerationButtonsWrapper } from "./GenerationButtons.styled";

const GenerationButtons = ({
  uniqueGeneration,
  selectedGeneration,
  setSelectedGeneration,
  comps,
  updateChartAndDescription,
  setFilteredComps, // Added prop to update filtered comps in parent
}) => {
  // Handle generation button click
  const handleGenerationClick = (generation) => {
    if (generation !== selectedGeneration) {
      
      setSelectedGeneration(generation); // Update the selected generation in the parent

      // Filter comps for the selected generation
      const filteredComps = comps.filter((comp) => comp.gen === generation);
      setFilteredComps(filteredComps); // Update filtered comps in parent

      // Update chart and description with the first matching comp
      const matchingComp = filteredComps[0];
      if (matchingComp) {
       
        updateChartAndDescription(matchingComp.widget, matchingComp.desc);
      }
    }
  };
// Default select the first generation in the list
useEffect(() => {
    if (uniqueGeneration.length > 0 && !selectedGeneration) {
        const firstGeneration = uniqueGeneration[0];
        
        handleGenerationClick(firstGeneration); // Automatically select the first generation
    }
    }, [uniqueGeneration, selectedGeneration]); // Run when uniqueGeneration or selectedGeneration changes

  return (
    <GenerationButtonsWrapper>
      <h5>Generations</h5>
      <div className="generation-buttons">
        {uniqueGeneration.map((generation, index) => (
          <button
            key={index}
            onClick={() => handleGenerationClick(generation)}
            className={generation === selectedGeneration ? "active" : ""} // Add active class if selected
          >
            {generation}
          </button>
        ))}
      </div>
    </GenerationButtonsWrapper>
  );
};

GenerationButtons.propTypes = {
  uniqueGeneration: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of unique generations
  selectedGeneration: PropTypes.string, // Current selected generation
  setSelectedGeneration: PropTypes.func.isRequired, // Function to update the selected generation
  comps: PropTypes.array.isRequired, // Array of comps for filtering and updates
  updateChartAndDescription: PropTypes.func.isRequired, // Function to update chart and description
  setFilteredComps: PropTypes.func.isRequired, // Function to update filtered comps in parent
};

export default GenerationButtons;
