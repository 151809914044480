import styled from 'styled-components';

export const VehicleCompsWrapper = styled.div`
 
  padding: 24px;
  background-color: #fff; /* Light background for readability */
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  @media (min-width: 768px) {
    padding: 48px; /* Match responsive padding */
  }
  
  .vehicle-comp-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .model-collapse-button {
    background-color: white;
    border: none;

    width: 24px;
    height: 24px;

    svg {
      transform: rotate(-360deg);
      transition: transform 250ms;
    }

    &.active {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .vehicle-comp-title {
    font-family: "Raleway", sans-serif; /* Main font */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #163750;

    margin: 0;

    @media (min-width: 768px) {
      font-size: 30px;
    }
  }

  .filter-buttons {
  
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
   
    display: flex;
    flex-direction: column; /* Stack count and buttons */
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;



    .listing-count {
      font-size: 14px;
      color: #555; /* Neutral color for less emphasis */
      margin-bottom: 5px; /* Space below the count */
      font-family: "Roboto", sans-serif; /* Complimentary font */

    }

    .button-container {
      display: flex;
      justify-content: space-between; /* Place button group and sort button on opposite ends */
      align-items: center; /* Center align vertically */
      width: 100%; /* Ensure it spans the container */


      @media (max-width: 1150px) {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: flex-start; /* Align items to the start */
        gap: 15px; /* Add space between stacked items */
      }
    }

    .button-group {
     
      display: flex;
      flex-wrap: wrap; /* Allow wrapping for small screens */
      gap: 10px; /* Space between buttons */
     
      

      button {
        padding: 8px 12px;
        border: 1px solid #5b89b4; /* Light Blue Border */
        background-color: #fff;
        color: #5b89b4; /* Light Blue Text */
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.2s ease;

        font-family: "Raleway", sans-serif; /* Consistent font */
        font-style: normal;
        font-weight: 500;
        max-height: 36px;

        &:hover {
          background-color: #5b89b4; /* Light Blue Background */
          color: #fff; /* White Text */
        }

        &.active {
          background-color: #236092; /* Dark Blue Background */
          color: #fff; /* White Text */
          border: 1px solid #236092; /* Dark Blue Border */
        }
      }
      @media (max-width: 768px) {
        flex-direction: column; /* Stack buttons vertically */
        width: 100%; /* Make button group take full width */
        gap: 15px; /* Adjust gap for better spacing */

        button {
          width: 100%; /* Make buttons take up the full width */
          text-align: center; /* Center text inside buttons */
        }
      }
    }

    .sort-button-container {

      display: flex;
      justify-content: flex-end; /* Align sort button to the far right */

      @media (max-width: 1150px) {
        justify-content: flex-start; /* Align sort button to the start on smaller screens */
        width: 100%; /* Ensure it spans the container */
      }
    }

    .sort-button {
      margin-right: 8px;
    }
    .filter-button,
    .sort-button {
      padding: 8px 12px;
      border: 1px solid black; /* Match the SVG stroke color */
      background-color: #fff; /* White background */
      color: black; /* Match the SVG stroke color */
      border-radius: 4px;
      font-size: 14px;
      font-family: "Raleway", sans-serif; /* Consistent font */
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex; /* Allow inline elements inside button */
      align-items: center; /* Center text and SVG vertically */
      justify-content: space-between;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 0; /* Prevents shrinking */
      
      .button-text {
        flex: 1; /* Makes the text take available space */
        overflow: hidden; /* Prevents overflow */
        text-overflow: ellipsis; /* Adds "..." when text overflows */
        white-space: nowrap; /* Ensures it stays on one line */
      }
      &:hover {
        background-color:rgb(170, 197, 222); /* Slightly darker white for hover */
        color: black; /* Keep the font color consistent */
      }

      .dropdown-arrow {
        margin-left: 8px; /* Add spacing between text and arrow */
      }
      @media (max-width: 1150px) {
        white-space: nowrap; 
        text-overflow: ellipsis;
        max-width: 125px;
      }
    }

    .dropdown-content {
      position: absolute;
      top: 100%;
      right: 0; /* Align dropdown with the sort button */
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for depth */
      z-index: 1000;
      margin-top: 5px;

      div {
        padding: 8px 12px;
        font-size: 14px;
        font-family: "Roboto", sans-serif; /* Complimentary font for dropdown */
        color: #333;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: rgb(170, 197, 222);
          color: #fff;
        }
      }
    } 

    .dropdown-filter-content {
      position: fixed; /* Keep it fixed for a consistent layout */
      top: 0;
      right: 0;
      width: 400px; /* Take up half of the viewport width */
      height: 100vh; /* Full viewport height */
      background-color: #ffffff; /* Clean white background for contrast */
      border: 1px solid #ddd; /* Subtle border for a clean look */
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow for depth */
      z-index: 1000;
      display: flex; /* Use flexbox for a structured layout */
      flex-direction: column; /* Stack content vertically */
      padding: 16px; /* Comfortable padding around the content */
      box-sizing: border-box; /* Include padding and border in width/height */
      overflow-y: hidden; /* Scrollable for overflow content */
    }

    .filter-options-container {
      flex: 1; /* Allow the container to grow and take available space */
      overflow-y: auto; /* Enable scrolling for the filter options */
      margin-bottom: 70px; /* Space for the fixed buttons */
    }
    

    /* Column-Specific Styling */
    .time-range-column,
    .model-year-column,
    .originality-status-column,
    .price-column,
    .odometer-column {
      border: 2px solid #ddd; /* Consistent border for all columns */
      margin-bottom: 12px; /* Space between columns */
      border-radius: 8px; /* Rounded corners for a polished look */
      padding: 12px; /* Padding inside each column */
      background-color: #f9f9f9; /* Light background for contrast */
    }

    /* Heading Style */
    .time-range-heading,
    .model-year-heading,
    .originality-status-heading,
    .price-heading,
    .odometer-heading {
      font-size: 18px; /* Slightly larger font for emphasis */
      font-family: "Raleway", sans-serif; /* Matches your main font */
      font-weight: bold;
      color: #163750; /* Dark blue for a professional look */
      margin-bottom: 12px; /* Space below the heading */
    }

    /* Options Style */
    .time-range-options,
    .model-year-options,
    .originality-status-options,
    .price-options,
    .odometer-options {
      display: flex;
      flex-direction: column; /* Stack radio buttons vertically */
      gap: 12px; /* Space between options */
    }

    .time-range-options label,
    .model-year-options label,
    .originality-status-options label,
    .price-options label,
    .odometer-options label {
      display: flex;
      align-items: center; /* Align text and radio button */
      font-size: 14px;
      font-family: "Roboto", sans-serif; /* Complimentary font for options */
      color: #555; /* Subtle text color for less emphasis */
      cursor: pointer;
      transition: all 0.2s ease;
    }

    .time-range-options label:hover,
    .model-year-options label:hover,
    .originality-status-options label:hover,
    .price-options label:hover,
    .odometer-options label:hover {
      background-color: #f4f4f9; /* Light background on hover */
      border-radius: 4px; /* Rounded corners for hover effect */
    }

    .time-range-options input[type="radio"],
    .model-year-options input[type="checkbox"],
    .originality-status-options input[type="checkbox"],
    .price-options input[type="radio"],
    .odometer-options input[type="radio"] {
      margin-right: 8px; /* Space between radio button and label */
      accent-color: #5b89b4; /* Match theme color for radio button */
      cursor: pointer;
    }
      

    /* Scrollbar Styling */
    .dropdown-filter-content::-webkit-scrollbar {
      width: 10px;
    }

    .dropdown-filter-content::-webkit-scrollbar-thumb {
      background-color: #5b89b4; /* Matches theme */
      border-radius: 4px;
    }

    .dropdown-filter-content::-webkit-scrollbar-thumb:hover {
      background-color: #236092; /* Darker on hover */
    }
  }
  .sales-group {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    align-items: center;
    gap: 20px; /* Fixed space between Low, Average, and High */
    margin-top: 10px; /* Space above the sales group */
    
    font-family: "Raleway", sans-serif; /* Main font */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    color: #163750; /* Dark Blue for emphasis */

    p {
      margin: 0; /* Remove default margin */
      padding: 0; /* Remove default padding */
    }
  }

  .comp-results-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh; /* Limit height for better UX */
    overflow-y: auto; /* Scroll if content overflows */
    font-size: 12px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f9f9f9; /* White background for contrast */
  }

  .price-dropdown select, 
  .time-range-options select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff;
    color: #333;
    transition: border-color 0.2s ease;
    cursor: pointer;

    /* Add some hover and focus effects */
    &:hover {
      border-color: #bbb;
    }

    &:focus {
      border-color: #236092;
      outline: none;
    }
  }
  .odometer-dropdown select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff;
    color: #333;
    transition: border-color 0.2s ease;
    cursor: pointer;

    /* Add some hover and focus effects */
    &:hover {
      border-color: #bbb;
    }

    &:focus {
      border-color: #236092;
      outline: none;
    }
  }
  /* Fixed Action Buttons */
  .filter-actions {
    width: 375px;
    position: fixed;
    bottom: 16px; /* Space from the bottom */
    right: 16px; /* Padding from the right */
    display: flex;
    justify-content: flex-end;
    gap: 16px; /* Space between buttons */
    background-color: #ffffff; /* Matches the dropdown background */
    padding: 8px 16px; /* Padding around buttons */
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
    border-radius: 8px; /* Rounded corners for a professional look */
  }

  /* Apply Button */
  .apply-button {
    background-color: #d9534f; /* Professional red color */
    color: #fff;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background-color: #c9302c; /* Darker red on hover */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    }
  }

  /* Reset Button */
  .reset-button {
    margin-left: 8px;
    background-color: #fff;
    color: #555;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      background-color: #f9f9f9;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    }
  }
  .loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto; /* Center horizontally and add spacing */
    font-size: 14px; /* Font size for "Loading more vehicles..." */
    color: #555; /* Subtle text color */
  }

  .spinner {
    margin-left: 8px; /* Space between text and spinner */
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light gray border */
    border-left-color: #555; /* Darker left border for spinner effect */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite; /* Smooth spinning animation */
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .comp-results-container::after {
    content: "";
    display: block;
    height: 100px; /* Adjust as needed */
  }
  .vehicle-comp-item.new-item {
    animation: fadeIn 0.5s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .loading-indicator.sticky {
    position: sticky;
    bottom: 0;
    background-color: #f9f9f9; /* Match your app's background */
    padding: 8px 0;
    z-index: 10; /* Keep it above other content */
  }

  .applied-filters {
    margin-top: 0px;
    padding: 8px;
    background-color: #f9f9f9;
    border-radius: 4px;
    overflow: hidden;
  }

  .applied-filters ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex; /* Place list items side by side */
    flex-wrap: wrap; /* Allows items to wrap to next line */
    gap: 8px; /* Space between filter items */
    align-items: center; /* Align items vertically */
  }

  .applied-filters li {
    font-size: 14px;
    color: #555;
    margin-right: 12px; /* Add space between items */
    display: flex; /* Flexbox to align content within each item */
    align-items: center; /* Center content vertically */
    justify-content: right; /* Align content to the right */
    padding: 6px 10px; /* Add padding inside each item */
    background-color: #fff; /* White background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-radius: 4px; /* Rounded corners for a clean look */
    max-width: 100%; /* Ensures elements don’t exceed the container */
    flex-shrink: 1; /* Allows items to shrink if needed */
  }

  .remove-filter-button {
    background: none; /* Remove background */
    border: none; /* Remove border */
    padding: 0; /* Remove padding */
    margin-left: 8px; /* Add space between text and button */
    cursor: pointer; /* Show pointer on hover */
    display: flex; /* Ensures proper alignment */
    align-items: center;
  }

  .remove-filter-button svg {
    width: 14px; /* Adjust size of the SVG */
    height: 14px;
    fill: red; /* Make the "X" red */
    stroke: none; /* Remove stroke for a clean appearance */
  }

  /* Responsive Adjustments */
  @media screen and (max-width: 768px) {
    .applied-filters {
      padding: 6px;
    }

    .applied-filters ul {
      gap: 6px; /* Reduce gap for smaller screens */
      justify-content: flex-start; /* Ensure filters stay aligned left */
    }

    .applied-filters li {
      font-size: 12px; /* Reduce font size */
      padding: 5px 8px; /* Reduce padding */
    }

    .remove-filter-button svg {
      width: 10px; /* Make the remove button smaller */
      height: 10px;
    }
  }



`;



export const VehicleCompItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch; /* Ensure all columns have the same height */
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 8px;
  height: auto;
  background-color: #fff;
  box-sizing: border-box;
  gap: 10px;
  transition: box-shadow 0.1s ease;


  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .small-screen-container {
 
    display: flex;
    justify-content: space-between; /* Distribute space evenly */
    flex-direction: row; /* Align child elements horizontally */
    align-items: stretch; /* Ensure equal height for all columns */
    gap: 10px; /* Add spacing between the columns */
    width: 100%; /* Use full width of the container */
   
  }

  .column {
    flex-grow: 1;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    min-width: 50px;
  }

  .image-column {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px; /* Fixed width for all image columns */
    height: 120px; /* Fixed height for all image columns */
    overflow: hidden; /* Clip overflowing content */
    border-radius: 10px; /* Apply rounded corners */
    box-sizing: border-box; /* Include border in size */
    background-color: #f4f4f4; /* Light background for consistent look */
    flex-shrink: 0; /* Prevent resizing of the image column */
    flex-grow: 0; /* Prevent growing of the image column */

    img {
      display: block; /* Prevent inline-block spacing issues */
      max-width: 100%; /* Scale down if larger */
      max-height: 100%; /* Scale down if larger */
      width: 200px; /* Ensure fixed width */
      height: 120px; /* Ensure fixed height */
      object-fit: cover; /* Crop and scale the image to fill the container */
      object-position: center; /* Center the image within the container */
      border-radius: inherit; /* Match the container's rounded corners */
    }
  }

  .details-column {
   
    flex-grow: 2;
    min-width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;

    p {
      margin: 0;
      padding: 0;
      line-height: 1.2;
    }

    p:first-child {
      color: #236092; /* Dark Blue Text */
      font-weight: bold;
    }
  }

  .status-column,
  .listing-type-column,
  .lot-date-column {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 0px;
    box-sizing: border-box;
  }

  .lot-date-column {
    flex: 0 0 auto; /* Prevent flex-grow and flex-shrink */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
    width: auto; /* Dynamically adjust width based on content */
  }

  .status-column {
    
    align-items: center; /* Vertically align children */
  }

  .listing-type-column,
  .lot-date-column {
    justify-content: flex-start; /* Align content at the start */
  }

  .column p {
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  /* Responsive styles for stacking at 1075px */
  @media (max-width: 1125px) and (min-width: 996px) {
    .small-screen-container {
      flex-direction: column; /* Stack vertically */
      gap: 10px; /* Add minimal spacing between stacked items */
      align-items: center; /* Center all stacked items */
      width: 100%; /* Use full width of the container */
    }

    .status-column,
    .listing-type-column,
    .lot-date-column {
      width: 100%; /* Take full width in the stacked layout */
      display: flex; /* Enable flexbox layout */
      flex-direction: column; /* Stack text vertically */
      align-items: center; /* Center items horizontally */
      justify-content: center; /* Center content vertically */
      gap: 5px; /* Add minimal spacing between items within the column */
      text-align: center; /* Align text to the center */
    }

    .status-column p,
    .listing-type-column p,
    .lot-date-column p {
      margin: 0; /* Remove default margins */
      padding: 0; /* Remove default padding */
    }
  }

  /* Responsive styles for smaller screens */
  @media (max-width: 995px) {
    flex-direction: column;
    align-items: center;


    .small-screen-container {
   
      justify-content: space-between; /* Distribute space evenly */
      flex-direction: row; /* Align child elements horizontally */
      align-items: stretch; /* Ensure equal height for all columns */
      gap: 10px; /* Add spacing between the columns */
      width: 100%; /* Use full width of the container */
    }

    .image-column {
      width: 100%; /* Expand to full width */
      height: auto; /* Allow height to adjust dynamically */
      max-height: 300px; /* Limit max height for better visibility */

      img {
        width: 100%; /* Fill container width */
        height: auto; /* Maintain aspect ratio */
        object-fit: contain; /* Prevent excessive zoom or cropping */
        object-position: center; /* Center the image within the container */
      }
    }

    .details-column { 
      width: 100%;
      max-width: 100%;
      min-width: 0px;
      overflow-wrap: anywhere; /* Allow breaking words at any point if necessary */
      word-break: break-word; /* Break long words onto the next line */
      white-space: normal; /* Allow wrapping for text */
      line-height: 1.5; /* Improve readability for wrapped text */
      box-sizing: border-box; /* Include padding and border in width calculations */
      align-items: flex-start; /* Align items to the left */
      text-align: left; /* Align text to the left */
      display: flex; /* Use flexbox for layout */
      flex-direction: column; /* Ensure children stack vertically */
    }


    .status-column {
      width: 100%; /* Ensure full width */
      display: flex; /* Enable flexbox layout */
      justify-content: space-between; /* Push items to opposite ends */
      align-items: flex-start; /* Vertically align to the center */

      p {
        margin: 0;
        display: inline-block; /* Make <p> tags align horizontally */
      }

      .p-status {
        text-align: left; /* Align to the left */
        flex: 0 0 auto; /* Prevent stretching or shrinking */
      }

      .p-price {
        text-align: right; /* Align to the right */
        flex: 0 0 auto; /* Prevent stretching or shrinking */
      }
    }

    .listing-type-column {
      width: 100%;
      display: flex;
      justify-content: space-between; /* Place first <p> on left and second <p> on right */
    }

    .lot-date-column {
      
      display: flex;
      justify-content: flex-end;
      width: auto;
    }
  }

  

`;
