import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { VehicleCompsWrapper } from "./VehicleComps.styled";
import { useSortAndFilter, getFilteredComps } from "./SortAndFilter"; 
import ButtonChevron from "../ButtonChevron";
import InfiniteScroll from "./InfiniteScroll";

const VehicleComps = ({ comps, updateChartAndDescription }) => {
  if (!comps || comps.length === 0) {
    return <div>No vehicle comps available.</div>;
  }

  // Compute max price dynamically
  const prices = comps.map((comp) => {
    let price_value = comp.compVehicle?.price_usd || 0;
    if (comp.compVehicle?.price_currency?.startsWith("$")) {
      price_value = parseInt(comp.compVehicle.price_currency.replace(/[^0-9]/g, ""), 10);
    }
    return price_value;
  });

  const maxPrice = Math.max(...prices);

  // Compute max mileage dynamically
  const mileages = comps.map((comp) => comp.compVehicle?.mileage_in_miles || 0);

  const maxOdometer = Math.max(...mileages);
 

  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedOption, setSortOption] = useState("Date (most recent)");
  const [selectedTimeRange, setSelectedTimeRange] = useState(null); // No default time range
  const [appliedTimeRange, setAppliedTimeRange] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
  const [selectedOriginalityStatuses, setSelectedOriginalityStatuses] = useState([]); // Temporary originality selection
  const [appliedOriginalityStatuses, setAppliedOriginalityStatuses] = useState([]); // Applied originality statuses
  

  
  // State for price range
  const [priceRange, setPriceRange] = useState(null);
  const [appliedPriceRange, setAppliedPriceRange] = useState(null); // Applied range
  
  const [odometerRange, setOdometerRange] = useState(null); // Temporary odometer range
  const [appliedOdometerRange, setAppliedOdometerRange] = useState(null); // Applied odometer range
  


  const [isExpanded, setIsExpanded] = useState(true); // Initialize state for ButtonChevron
  const sortDropdownRef = useRef(null);
  const filterDropdownRef = useRef(null);

  useEffect(() => {
    // Reset filters when `comps` changes
    setSelectedTimeRange(null); 
    setAppliedTimeRange(null);
    setSelectedOriginalityStatuses([]);
    setAppliedOriginalityStatuses([]);
    setPriceRange(null);
    setAppliedPriceRange(null);
    setOdometerRange(null);
    setAppliedOdometerRange(null);
    setIsFilterDropdownVisible(false);
  }, [comps]); // Runs only when `comps` change

  const toggleExpand = () => setIsExpanded((prev) => !prev); // Toggle function for ButtonChevron
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        sortDropdownRef.current &&
        !sortDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setIsFilterDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  

  const { activeFilter, setActiveFilter, sortedComps, soldComps, otherComps } =
    useSortAndFilter(comps, selectedVariant, selectedOption, appliedTimeRange, appliedPriceRange, appliedOdometerRange, appliedOriginalityStatuses);


  const filteredListings = getFilteredComps(
    activeFilter,
    sortedComps,
    soldComps,
    otherComps
  );

  

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [year, month, day] = dateString.split("-");
    return `${months[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
  };

  return (
    <VehicleCompsWrapper id="vehicleComps">
      {/* Heading with Chevron */}
      <div className="vehicle-comp-header">
        <h2 className="vehicle-comp-title">Vehicle Comps</h2>
        <ButtonChevron
          className="model-collapse-button"
          isExpanded={isExpanded}
          onToggleExpand={toggleExpand}
        />
      </div>

      {isExpanded && (
        <>
          <div className="filter-buttons">
            <p className="listing-count">
              {filteredListings.length} of {sortedComps.length} related listings
            </p>
            <div className="button-container">
              <div className="button-group">
                <button
                  onClick={() => setActiveFilter("ALL")}
                  className={activeFilter === "ALL" ? "active" : ""}
                >
                  All Listings
                </button>
                <button
                  onClick={() => setActiveFilter("SOLD")}
                  className={activeFilter === "SOLD" ? "active" : ""}
                >
                  Sold
                </button>
                <button
                  onClick={() => setActiveFilter("FOR_SALE")}
                  className={activeFilter === "FOR_SALE" ? "active" : ""}
                >
                  For Sale
                </button>
              </div>
              <div className="sort-button-container">
                {/* Sort Dropdown */}
                <div className="dropdown" ref={sortDropdownRef}>
                  <button
                    className="sort-button"
                    onClick={() => setIsDropdownVisible((prev) => !prev)}
                  >
                    <span className="button-text">Sort: {selectedOption}</span>
                    <span className="dropdown-arrow">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 9L12 15L18 9"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </button>
                  {isDropdownVisible && (
                    <div className="dropdown-content">
                      <div
                        onClick={() => {
                          setSortOption("Date (most recent)");
                          setIsDropdownVisible(false);
                        }}
                      >
                        Date (most recent)
                      </div>
                      <div
                        onClick={() => {
                          setSortOption("Date (least recent)");
                          setIsDropdownVisible(false);
                        }}
                      >
                        Date (least recent)
                      </div>
                      <div
                        onClick={() => {
                          setSortOption("Price (highest first)");
                          setIsDropdownVisible(false);
                        }}
                      >
                        Price (highest first)
                      </div>
                      <div
                        onClick={() => {
                          setSortOption("Price (lowest first)");
                          setIsDropdownVisible(false);
                        }}
                      >
                        Price (lowest first)
                      </div>
                      <div
                        onClick={() => {
                          setSortOption("Mileage (highest first)");
                          setIsDropdownVisible(false);
                        }}
                      >
                        Mileage (highest first)
                      </div>
                      <div
                        onClick={() => {
                          setSortOption("Mileage (lowest first)");
                          setIsDropdownVisible(false);
                        }}
                      >
                        Mileage (lowest first)
                      </div>
                    </div>
                  )}
                </div>

                {/* Filter Dropdown */}
                <div className="dropdown" ref={filterDropdownRef}>
                  <button
                    className="filter-button"
                    onClick={() => setIsFilterDropdownVisible((prev) => !prev)}
                  >
                    Filters
                  </button>
                  {isFilterDropdownVisible && (
                    <div className="dropdown-filter-content">
                      <div className="filter-options-container">
                        {/* Time Range Column */}
                        <div className="time-range-column filter-column">
                          <h3 className="time-range-heading">Time Range</h3>
                          <div className="time-range-options">
                            <select
                              name="timeRange"
                              value={selectedTimeRange}
                              onChange={(e) => setSelectedTimeRange(e.target.value)}
                            >
                              {["5 years", "3 years", "1 year", "6 months"].map((range) => (
                                <option key={range} value={range}>
                                  {range}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* Price Column */}
                        <div className="price-column filter-column">
                          <h3 className="price-heading">Price</h3>
                          {/* Dropdown for Price Range */}
                          <div className="price-dropdown">
                            <select
                              value={priceRange}
                              onChange={(e) => {
                                const selectedMax = Number(e.target.value);
                                setPriceRange(selectedMax); // Update the max value based on selection
                              }}
                            >
                              {[maxPrice, maxPrice * 0.75, maxPrice * 0.5, maxPrice * 0.25].map(
                                (price, index) => (
                                  <option key={index} value={Math.floor(price)}>
                                    Up to ${Math.floor(price).toLocaleString()}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        {/* Odometer Column */}
                        <div className="odometer-column filter-column">
                          <h3 className="odometer-heading">Odometer</h3>
                          <div className="odometer-dropdown">
                            <select
                              value={odometerRange}
                              onChange={(e) => setOdometerRange(Number(e.target.value))}
                            >
                              {[maxOdometer, maxOdometer * 0.75, maxOdometer * 0.5, maxOdometer * 0.25].map(
                                (mileage, index) => (
                                  <option key={index} value={Math.floor(mileage)}>
                                    Up to {Math.floor(mileage).toLocaleString()} miles
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>

                        {/* Originality Status Column */}
                        <div className="originality-status-column filter-column">
                          <h3 className="originality-status-heading">Condition</h3>
                          <div className="originality-status-options">
                            {Array.from(
                              new Set(
                                comps
                                  .map((comp) =>
                                    comp.compVehicle?.attributes?.find(
                                      (attr) => attr.kind === "CONSERVATION"
                                    )?.value || "Unknown" // Extract originality status or default to "Unknown"
                                  )
                                  .filter(Boolean) // Remove null or undefined entries
                              )
                            )
                              .sort() // Sort the originality statuses alphabetically
                              .map((status) => (
                                <label key={status}>
                                  <input
                                    type="checkbox"
                                    name="originalityStatus"
                                    value={status}
                                    checked={selectedOriginalityStatuses.includes(status)} // Check if the status is selected
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setSelectedOriginalityStatuses((prev) =>
                                        prev.includes(value)
                                          ? prev.filter((s) => s !== value) // Remove if unchecked
                                          : [...prev, value] // Add if checked
                                      );
                                    }}
                                  />
                                  {status}
                                </label>
                              ))}
                          </div>
                        </div>
                      </div>
                      {/* Action Buttons */}
                      <div className="filter-actions">
                        <button
                          className="reset-button"
                          onClick={() => {
                            setSelectedTimeRange(null); // Reset temporary selection
                            setAppliedTimeRange(null); // Reset applied selection
                            setSelectedOriginalityStatuses([]); // Reset selection
                            setAppliedOriginalityStatuses([]); // Reset applied statuses
                            setPriceRange(null); // Reset the dropdown to the maximum price
                            setAppliedPriceRange(null); // Reset the applied price range to the maximum
                            setOdometerRange(null); // Reset the dropdown to the maximum odometer value
                            setAppliedOdometerRange(null); // Reset the applied odometer range to the maximum
                            setIsFilterDropdownVisible(false);
                          }}
                        >
                          Reset
                        </button>
                        <button
                          className="apply-button"
                          onClick={() => {

                            setAppliedTimeRange(selectedTimeRange); // Apply the selected time range

                            setAppliedPriceRange(priceRange); // Apply the selected price range
                            setAppliedOdometerRange(odometerRange); // Apply the selected odometer range
                            setAppliedOriginalityStatuses(selectedOriginalityStatuses); // Apply originality statuses

                            setIsFilterDropdownVisible(false);

                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Display Applied Filters */}
            {(appliedTimeRange || appliedPriceRange || appliedOdometerRange || appliedOriginalityStatuses.length > 0) && (
              <div className="applied-filters">
                <ul>
                  {appliedTimeRange && (
                    <li>
                      {appliedTimeRange}
                      <button
                        className="remove-filter-button"
                        onClick={() => {
                          setSelectedTimeRange(null); // Reset selected time range
                          setAppliedTimeRange(null); // Reset applied time range
                        }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6L6 18"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 6L18 18"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </li>
                  )}
                  {appliedPriceRange && (
                    <li>
                      Up to ${appliedPriceRange.toLocaleString()}
                      <button
                        className="remove-filter-button"
                        onClick={() => {
                          setPriceRange(null); // Reset selected price range
                          setAppliedPriceRange(null); // Reset applied price range
                        }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6L6 18"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 6L18 18"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </li>
                  )}
                  {appliedOdometerRange && (
                    <li>
                      Up to {appliedOdometerRange.toLocaleString()} miles
                      <button
                        className="remove-filter-button"
                        onClick={() => {
                          setOdometerRange(null); // Reset selected odometer range
                          setAppliedOdometerRange(null); // Reset applied odometer range
                        }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6L6 18"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 6L18 18"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </li>
                  )}
                  {appliedOriginalityStatuses.length > 0 && (
                    <li>
                      {appliedOriginalityStatuses.join(", ")}
                      <button
                        className="remove-filter-button"
                        onClick={() => {
                          setSelectedOriginalityStatuses([]); // Reset selected originality statuses
                          setAppliedOriginalityStatuses([]); // Reset applied originality statuses
                        }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6L6 18"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 6L18 18"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}

            <div className="sales-group">
              <p className="low-sales">
                Low: $
                {Math.min(
                  ...filteredListings.map(
                    (comp) => comp.price_usd || Infinity
                  )
                ).toLocaleString()}
              </p>
              <p className="average-sales">
                Average: $
                {Math.round(
                  filteredListings
                    .map((comp) => comp.price_usd || 0)
                    .reduce((sum, price) => sum + price, 0) /
                    filteredListings.length || 0
                ).toLocaleString()}
              </p>
              <p className="high-sales">
                High: $
                {Math.max(
                  ...filteredListings.map(
                    (comp) => comp.price_usd || -Infinity
                  )
                ).toLocaleString()}
              </p>
            </div>
          </div>
          {/* Infinite Scroll Component */}
          <InfiniteScroll
            filteredListings={filteredListings}
            formatDate={formatDate}
          />
        </>
      )}
    </VehicleCompsWrapper>
  );
};

VehicleComps.propTypes = {
  comps: PropTypes.array.isRequired,
  updateChartAndDescription: PropTypes.func.isRequired,
};

export default VehicleComps;
