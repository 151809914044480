import { useState } from "react";

export const getFilteredComps = (activeFilter, sortedComps, soldComps, otherComps) => {
  if (activeFilter === "ALL") {
    return sortedComps; // Show all listings
  }
  if (activeFilter === "SOLD") {
    return soldComps; // Show only sold comps
  }
  if (activeFilter === "FOR_SALE") {
    return otherComps; // Show only for-sale comps
  }
  return sortedComps; // Default to all listings if no filter matches
};

export const useSortAndFilter = (
  comps,
  selectedVariant,
  sortOption = "Date (most recent)",
  timeRange = null,
  priceRange = null, // New parameter for price range
  odometerRange = null,
  selectedOriginalityStatuses = []

) => {
  const [activeFilter, setActiveFilter] = useState("SOLD");

  const today = new Date();
  let startDate;

  // Calculate start date if a time range is specified
  if (timeRange) {
    switch (timeRange) {
      case "6 months":
        startDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case "1 year":
        startDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case "3 years":
        startDate = new Date(today.setFullYear(today.getFullYear() - 3));
        break;
      case "5 years":
        startDate = new Date(today.setFullYear(today.getFullYear() - 5));
        break;
      default:
        console.log("Invalid time range selected");
    }
  }

  // Filter comps based on selected variant, time range, model years, price range, and odometer range
  const filteredComps = comps.filter((comp) => {
    const variantMatch = selectedVariant ? comp.variant === selectedVariant : true;

    const timeRangeMatch = timeRange
      ? new Date(comp.compVehicle?.lot_date) >= startDate &&
        !isNaN(new Date(comp.compVehicle?.lot_date))
      : true;

    const priceMatch = priceRange
      ? comp.compVehicle?.price_usd <= priceRange
      : true; // Include all if no max price is selected

    const odometerMatch = odometerRange
      ? comp.compVehicle?.mileage_in_miles <= odometerRange
      : true; // Include all if no max odometer value is selected

    const originalityMatch = selectedOriginalityStatuses.length > 0
    ? (() => {
        const attributes = comp.compVehicle?.attributes || []; // Ensure attributes is an array
        const originalityAttribute = attributes.find(
          (attr) => attr.kind === "CONSERVATION"
        );
        const originalityValue = originalityAttribute?.value || "Unknown";
        return selectedOriginalityStatuses.includes(originalityValue);
      })()
    : true; // Include all if no originality statuses are selected
    

    return (
      variantMatch &&
      timeRangeMatch &&
      priceMatch &&
      odometerMatch &&
      originalityMatch
    );
  });


  // Sort comps based on the sort option
  const sortedComps = filteredComps
    .map((comp) => {
      let price_value = comp.compVehicle?.price_usd || 0; // Default to price_usd if price_currency is not valid
      if (comp.compVehicle?.price_currency?.startsWith("$")) {
        price_value = parseInt(comp.compVehicle.price_currency.replace(/[^0-9]/g, ""), 10);
      }

      const mileage_value = comp.compVehicle?.mileage_in_miles || 0; // Default mileage to 0 if not present

      return {
        ...comp.compVehicle,
        price_value, // Add price_value to the object for sorting purposes
        mileage_value, // Add mileage_value to the object for sorting purposes
      };
    })
    .filter((comp) => comp)
    .sort((a, b) => {
      if (sortOption === "Date (most recent)") {
        return new Date(b.lot_date) - new Date(a.lot_date);
      } else if (sortOption === "Date (least recent)") {
        return new Date(a.lot_date) - new Date(b.lot_date);
      } else if (sortOption === "Price (highest first)") {
        return b.price_value - a.price_value; // Sort by price_value descending
      } else if (sortOption === "Price (lowest first)") {
        return a.price_value - b.price_value; // Sort by price_value ascending
      } else if (sortOption === "Mileage (highest first)") {
        return b.mileage_value - a.mileage_value; // Sort by mileage descending
      } else if (sortOption === "Mileage (lowest first)") {
        return a.mileage_value - b.mileage_value; // Sort by mileage ascending
      }
      return 0; // Default to no sorting
    });

  // Separate sold and other comps
  const soldComps = sortedComps.filter((comp) => comp.status === "SOLD");
  const otherComps = sortedComps.filter((comp) => comp.status !== "SOLD");

  // Log the result
  const result = {
    activeFilter,
    setActiveFilter,
    sortedComps,
    soldComps,
    otherComps,
  };
 

  return result;
};

