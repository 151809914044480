import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import VehicleCompItem from "./VehicleCompItem";

const InfiniteScroll = ({ filteredListings, formatDate }) => {
  const [visibleVehicles, setVisibleVehicles] = useState(30); // Start with 30 vehicles
  const loadingRef = useRef(null); // Ref for the loading indicator

  const loadMoreVehicles = () => {
    setVisibleVehicles((prev) => Math.min(prev + 30, filteredListings.length)); // Load 30 more vehicles
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMoreVehicles();
        }
      },
      {
        root: null, // Observe in the viewport
        rootMargin: "100px", // Trigger when 100px near the bottom
        threshold: 1.0, // Fully visible
      }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [loadingRef.current]);

  return (
    <div className="comp-results-container">
      {filteredListings.slice(0, visibleVehicles).map((comp, index) => (
        <VehicleCompItem key={index} comp={comp} formatDate={formatDate} />
      ))}
      {visibleVehicles < filteredListings.length && (
        <div className="loading-indicator" ref={loadingRef}>
          Loading more vehicles...
        </div>
      )}
    </div>
  );
};

InfiniteScroll.propTypes = {
  filteredListings: PropTypes.array.isRequired,
  formatDate: PropTypes.func.isRequired,
};

export default InfiniteScroll;
