import React from "react";
import PropTypes from "prop-types";
import { VehicleCompItem as StyledVehicleCompItem } from "./VehicleComps.styled";

const VehicleCompItem = ({ comp, formatDate }) => {
  const attributes = Array.isArray(comp.attributes) ? comp.attributes : [];
  const conversionValue =
    attributes.find((attr) => attr.kind === "CONSERVATION")?.value || "N/A";
  const transTypeValue =
    attributes.find((attr) => attr.kind === "TRANSTYPE")?.value || "N/A";
  const handednessValue =
    attributes.find((attr) => attr.kind === "HANDEDNESS")?.value || "N/A";

  const formattedLotDate = comp.lot_date ? formatDate(comp.lot_date) : "N/A";
  const formattedMileage = comp.mileage_in_miles
    ? parseInt(comp.mileage_in_miles).toLocaleString()
    : "N/A";

  return (
    <StyledVehicleCompItem>
      <div className="image-column">
        {comp.image_url ? (
          <img
            src={comp.image_url}
            alt={comp.title ?? "Vehicle"}
          />
        ) : (
          <p>No Image Available</p>
        )}
      </div>
      <div className="details-column">
        <p>{comp.title ?? "N/A"}</p>
        <p>
          <strong>{conversionValue}</strong>
        </p>
        <p>{formattedMileage} miles</p>
        <p>
          {transTypeValue} {handednessValue}
        </p>
        <p>{comp.location_place_name ?? "N/A"}</p>
      </div>
      <div className="small-screen-container">
        <div className="status-column">
          <p className="p-status">
            <strong>{comp.status ?? "N/A"}</strong>
          </p>
          <p className="p-price">
            {comp.price_currency ? `${comp.price_currency}` : "N/A"}
          </p>
        </div>
        <div className="listing-type-column">
          <p>{comp.listing_type ?? "N/A"}</p>
        </div>
        <div className="lot-date-column">
          <p>{formattedLotDate}</p>
        </div>
      </div>
    </StyledVehicleCompItem>
  );
};

VehicleCompItem.propTypes = {
  comp: PropTypes.object.isRequired,
  formatDate: PropTypes.func.isRequired,
};

export default VehicleCompItem;
